* {
  box-sizing: border-box;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
}

html, body {
  height: 100%;
  color: #ccc;
  background: #ffe7e78f radial-gradient(circle, #97b2ffdb 0%, #e5edffe0 100%);
  margin: 0;
  padding: 0;
}

.user-input-box {
  width: 100%;
  background-color: #fff3f5;
  border: 1px dashed maroon;
  margin-top: 8px;
  padding: 12px;
}

/*# sourceMappingURL=index.1a4dc2f0.css.map */
