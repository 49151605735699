* {
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

html,
body {
    height: 100%;
    /* background: #e8e8e8; */
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    /* background-image: radial-gradient(circle, rgba(255, 253, 185, 1) 0%, rgba(218, 217, 163, 1) 100%); */
    /* background-image: url("C:/Users/tbudrewicz/Desktop/Projects/SpecteamOrders/assets/stacked-waves-haikei.png"); */
    background: linear-gradient(353deg, #f8f8f8, #ececec00);

    background: #ffe7e78f radial-gradient(circle, #97b2ffdb 0%, #e5edffe0 100%);
    color: #ccc;
}

.user-input-box {
    width: 100%;
    margin-top: 8px;
    background-color: #fff3f5;
    padding: 12px;
    border: 1px dashed maroon;
}